import { useState } from "react"
import { Link } from "react-router-dom"

const QuestionFour = () =>{

    const [data, setData] = useState([
        {
            id: '1',
            option: 'I am new to the world of tea'
        },
        {
            id: '2',
            option: 'I enjoy a tea for every mood'
        },
        {
            id: '3',
            option: 'I enjoy few cups of tea occasionally'
        }
    ])

    const [selected, setSelected] = useState('1')

    const handleClick = (e, option) =>{
        
        localStorage.setItem("preference",option)
        setSelected(option.id)
    }
    const options = data.map((option)=>{
        var classes
        if(option.id === selected)
            classes = 'center option option-clicked'
        else
            classes = 'center option '
        return(
            <div className={classes} onClick={(e)=>{handleClick(e,option)}}>
                {option.option}
            </div>
        )
    })



    return(
        <div>
            <div className = "container main">

                <div className = "heading-container">
                    <h1 style={{fontSize:'35px', textAlign:'center', paddingTop: '18px'}}>What is Your Budget for A new Matress?</h1>
                </div>

                <div style = {{marginTop: '50px'}} >
                {options } 
                </div>

                <div className = "buttons" >
                    <Link to="/page3"><button className = "secondary-button">back</button></Link>   
                    <Link to = '/result'><button className = "primary-button">next</button></Link>
                    <Link to="/result"><button className = "secondary-button">skip</button></Link>
                </div>
            </div>
        </div>
    )
}

export default QuestionFour