import '../App.css'
import Option1 from '../images/sleep1.png'
import Option2 from '../images/sleep2.png'
import Option3 from '../images/sleep3.png'
import { Link } from 'react-router-dom'

const QuestionThree = () =>{


    const handleChange = (e)=>{
        console.log(e.target.value)
        localStorage.setItem('position', e.target.value);
    }
    return (
        <div>
            <div>
            <div className = "container main">
            <div className = "heading-container">
                <h1 style={{fontSize:'35px', textAlign:'center', paddingTop: '18px'}}>What is Your Budget for A new Matress?</h1>
            </div>
            <div className = "container form">
                <div className="options">
                    <img src = {Option1} style = {{height: '250px'}}/>
                    <img src = {Option2} style = {{height: '250px'}} />
                    <img src = {Option3} style = {{height: '250px'}}/>
                </div>
                    
                <form className ="options" onChange={(e)=>{handleChange(e)}}>
                    <p>
                        <label>
                        <input name="group1" type="radio" value="1" />
                        <span>value1</span>
                        </label>
                    </p>
                    <p>
                        <label>
                        <input name="group1" type="radio" value="2" />
                        <span>Value2</span>
                        </label>
                    </p>
                    <p>
                    <label>
                    <input  name="group1" type="radio" value="3" />
                    <span>Value3</span>
                    </label>
                    </p>  
                </form>


                <div className = "buttons" >
                    <Link to="/page2"><button className = "secondary-button">back</button></Link>   
                    <Link to = '/page4'><button className = "primary-button">next</button></Link>
                    <Link to="/page4"><button className = "secondary-button">skip</button></Link>
                </div>
            </div>
            </div>
        </div>
        </div>
    )
}

export default QuestionThree