import './App.css';
import Home from './components/Home'
import QuestionOne from './components/QuestionOne'
import QuestionTwo from './components/QuestionTwo'
import QuestionThree from './components/QuestionThree'
import QuestionFour from './components/QuestionFour';
import Result from './components/Result'
import Slider from './components/Slider';

import {
  BrowserRouter,
  Switch,
  Route,
  Link
} from "react-router-dom";

function App() {

  return (
    <BrowserRouter>
      <div className="App">
        <Switch>
          <Route exact path ='/'>
            <Home />
          </Route>
          <Route exact path ='/slider'>
            <Slider />
          </Route>
          <Route path ='/page1'>
            <QuestionOne />
          </Route>

          <Route path ='/page2'>
            <QuestionTwo />
          </Route>

          <Route path ='/page3'>
            <QuestionThree />
          </Route>

          <Route path ='/page4'>
            <QuestionFour />
          </Route>
          
          <Route path ='/result'>
            <Result />
          </Route>

        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
