import '../App.css'
import Pic1 from '../images/pic1.png'
import { Link } from 'react-router-dom'

const Home = () =>{
    return(
        <div className = "container main">
            <div className = "heading-container">
                <h1 style={{color:"#1E3A79", fontSize:'40px', textAlign:'center', paddingTop: '18px'}}>Sample Heading</h1>
            </div>
            <div className = "content">
                <div className="picture">
                    <img src={Pic1} />
                </div>
                <div className = "text">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    
                    <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    <Link to = '/page1'><button className = "primary-button">Take The Quiz</button></Link>
                </div>

            </div>
        </div>
    )
}

export default Home