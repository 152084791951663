import { useState } from 'react'
import '../App.css'
import Option1 from '../images/option1.png'
import Option2 from '../images/option2.png'
import Option3 from '../images/option3.png'
import { Link } from 'react-router-dom'

const QuestionOne = () =>{
    const [choice, setChoice] = useState('')
    const handleChange = (e)=>{
        console.log("handle Change", e.target.value)
        setChoice(e.target.value)
        localStorage.setItem('price', choice);
    }
    return(
        <div>
            <div className = "container main">
            <div className = "heading-container">
                <h1 style={{fontSize:'35px', textAlign:'center', paddingTop: '18px'}}>What is Your Budget for A new Matress?</h1>
            </div>
            <div className = "container form">
                <div className="options">
                    <div className = "object" ><img src = {Option1} /></div>
                    <div className = "object" ><img src = {Option1} /></div>
                    <div className = "object" ><img src = {Option1} /></div>
                </div>

                <form className ="options" onChange={(e)=>{handleChange(e)}}>
                    <p className = "object">
                        <label>
                        <input name="group1" type="radio" value = "1" />
                        <span>UNDER $1,000</span>
                        </label>
                    </p>
                    <p className = "object">
                        <label>
                        <input name="group1" type="radio" value = "2" />
                        <span>$1,000 TO $2,000</span>
                        </label>
                    </p>
                    <p className = "object">
                        <label>
                        <input name="group1" type="radio" value = "3" />
                        <span>$2,000</span>
                        </label>
                    </p>
                </form>
                    
                <div className = "buttons" >
                    <Link to="/"><button className = "secondary-button">back</button></Link>
                    <Link to = '/page2'><button className = "primary-button">next</button></Link>
                    <Link to="/page2"><button className = "secondary-button">skip</button></Link>
                </div>
            </div>
            </div>
        </div>
    )
}

export default QuestionOne