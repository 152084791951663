import '../App.css'
import Option1 from '../images/sleep1.png'
import Option2 from '../images/sleep2.png'
import Option3 from '../images/sleep3.png'
import Slider, { SliderTooltip } from 'rc-slider';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const QuestionTwo = () =>{
    const [picValue,setPicValue] = useState('1')

    const handleChange = (e) =>{
        console.log("Target: ", e.target.value)
        setPicValue(e.target.value)
        localStorage.setItem('size', picValue);

    } 
    const getPicture = ()=>{
      if (picValue === '1' || picValue === '4' )
        return Option1
      else if(picValue === '2' || picValue === '5')
        return Option2
      else if(picValue === '3' || picValue === '6')
       return Option3
    }
    

    return(
        <div>
            <div>
            <div className = "container main">
            <div className = "heading-container">
                <h1 style={{fontSize:'35px', textAlign:'center', paddingTop: '18px'}}>What is Your Budget for A new Matress?</h1>
            </div>
            <div className = "container form">
              <div style = {{width:'50%', margin:'auto', height:'250px'}}>
                <img src = {getPicture()} style = {{height: '250px' , marginLeft:'75px',}} />
              </div>
              <form className ="options" onChange={handleChange}>
                <p>
                  <label>
                  <input name="group1" type="radio" value="1"/>
                  <span>value 1</span>
                 </label>
                </p>
                
                <p>
                  <label>
                  <input name="group1" type="radio" value="2" />
                  <span>value 2</span>
                 </label>
                </p>

                <p>
                  <label>
                  <input name="group1" type="radio" value="3"  />
                  <span>value 3</span>
                 </label>
                </p>

                <p>
                  <label>
                  <input name="group1" type="radio" value="4"  />
                  <span>value 4</span>
                 </label>
                </p>

                <p>
                  <label>
                  <input name="group1" type="radio" value="5"  />
                  <span>value 5</span>
                 </label>
                </p>

                <p>
                  <label>
                  <input name="group1" type="radio" value="6" />
                  <span>value 6</span>
                 </label>
                </p>

              </form>
            <div className = "buttons" >
                <Link to="/page1"><button className = "secondary-button">back</button></Link>
                <Link to = '/page3'><button className = "primary-button">next</button></Link>
                <Link to="/page3"><button className = "secondary-button">skip</button></Link>
            </div>
            </div>
            </div>
        </div>
        </div>
    )
}

export default QuestionTwo