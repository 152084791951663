import '../App.css'
import Result1 from '../images/result.jpg'
import Result2 from '../images/result2.jpeg'
import Result3 from '../images/result3.jpg'

const Result = () =>{
    const packages = [
        {
            id: 1,
            title: 'package1',
            property1: 'medium ',
            property2: 'latex ',
            property3: '120 days',
            property4: 'Lifetime',
            price: '12,000Rs',
            img: Result1
        },
        {
            id: 2,
            title: 'package2',
            property1: 'firm- 6.5/10 ',
            property2: 'latex hybrid',
            property3: '365 nights days',
            property4: '25-year warranty',
            price: '20,000Rs',
            img: Result2
        },
        {
            id: 3,
            title: 'package3',
            property1: 'firm- 6.5/10 ',
            property2: 'latex',
            property3: '6 months',
            property4: '30-year warranty',
            price: '25,000Rs',
            img: Result2
        },
        {
            id: 4,
            title: 'package4',
            property1: 'firm- 6.5/10 ',
            property2: 'latex hybrid',
            property3: '365 nights days',
            property4: '25-year warranty',
            price: '29,000Rs',
            img: Result3
        },
        {
            id: 5,
            title: 'package5',
            property1: 'firm- 6.5/10 ',
            property2: 'latex hybrid',
            property3: '365 nights days',
            property4: '25-year warranty',
            price: '35,000Rs',
            img: Result2
        },
        {
            id: 6,
            title: 'package6',
            property1: 'firm- 6.5/10 ',
            property2: 'latex hybrid',
            property3: '365 nights',
            property4: '25-year warranty',
            price: '45,000Rs',
            img: Result1
        },
    ]

    const packageList = (id) => packages.map((offer)=>{
        if(id.includes(offer.id))
        {
            return(
                <div className="col s12 m4 center" style={{marginBottom:'10px'}}>
                        <img src={offer.img} style={{width:'100%',height: '250px'}} />
                        <h4 className="center"  >{offer.title}</h4>
                        <div className="detail">
                            <p className ="property">Property 1: </p>
                            <p className = "value">{offer.property1}</p>
                        </div>
                        <div className="detail">
                            <p className ="property">Property 1:</p>
                            <p className = "value">{offer.property2}</p>
                        </div>
                        <div className="detail">
                            <p className ="property">Property 1: </p>
                            <p className = "value">{offer.property3}</p>
                        </div>
                        <div className="detail">
                            <p className ="property">Property 1: </p>
                            <p className = "value">{offer.property4}</p>
                        </div>
                        <br/>
                        <button className="center primary-button">Search Price</button>
                    </div>
            )
        }
    })

    var offerList;
    if(localStorage.getItem("size")=='option3' && localStorage.getItem("price")=='2' && localStorage.getItem("position")=='1')
    offerList = packageList([4,5,6])
    else
    offerList = packageList([1,2,3])
    return(
        <div>
            <div className = "container main ">
                <div className = "heading-container">
                    <h1 style={{fontSize:'35px', textAlign:'center', paddingTop: '18px'}}>Your Results</h1>
                </div>
                <br />
                <div className="row" >
                {offerList}
                </div>
            </div>


        </div>
    )
}

export default Result